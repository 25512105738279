



























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardComponent from "@/commoncomponents/CardComponent.vue";
import { ScreenText } from "@/lang/ScreenText";
import ToggleSwitch from "@/commoncomponents/ToggleSwitch.vue";
import APP_UTILITIES from "@/utilities/commonFunctions";
import { ToastType } from "@/Model/toastType";
import { OfflinePaymentsOption } from "@/Model/payments/types";
import { updateOfflinePaymentsOptionStatus } from "@/services/payments/api";

@Component({
  components: {
    CardComponent,
    ToggleSwitch
  }
})
export default class OfflinePaymentsDetail extends Vue {
  enabled = false;
  // Initial flag to indicate first render state
  private _firstRender = true;
  disableSwitch = false;
  OFFLINE_PAYMENTS = 'Offline Payments';

  name = "";

  @Prop({ required: true }) offlinePaymentsOption: OfflinePaymentsOption | undefined;

  mounted() {
    this._firstRender = false;
    const data = this.offlinePaymentsOption
      ? { ...this.offlinePaymentsOption }.isEnabled
      : false;
    this.enabled = data;
  }

  readonly screenText = new ScreenText();

  toggleEditOfflinePayments() {
    this.$emit("EditOfflinePayments");
  }

  onStatusUpdated() {
    this.$emit("OnStatusUpdated");
  }

  @Watch("enabled")
  async onEnabledChanged() {
    if (
      !this._firstRender &&
      this.offlinePaymentsOption &&
      this.offlinePaymentsOption.isEnabled !== this.enabled
    ) {
      try {
        this.disableSwitch = true;
        await updateOfflinePaymentsOptionStatus({
          isEnabled: this.enabled,
          offlinePaymentsId: this.offlinePaymentsOption.id
        });
        this.onStatusUpdated();
        APP_UTILITIES.showToastMessage(
          `Offline payments are ${!this.enabled ? "inactive" : "active"}`,
          ToastType.Success
        );
      } catch {
        APP_UTILITIES.showToastMessage("Unexpected Error!", ToastType.Error);
        this.enabled = !this.enabled;
      } finally {
        this.disableSwitch = false;
      }
    }
  }
}
